import React from 'react';
import DHL from '../assets/CourierLogos/DHL.png';

function importAll(r) {
    console.log(r.keys()); // Log paths to ensure Webpack finds files
    return r.keys().map(r);
}

const images = importAll(require.context('../assets/CourierLogos', false, /\.(png|jpe?g|svg)$/));
console.log(images); // Check structure of images array

function LogosTicker() {
    return (
        <div className="logos-ticker">
            <ul>
                {images.map((img, index) => (
                    <li>
                        <div key={index} className="logo-cntner">
                            {/* Use img.default if the default key exists */}
                            <img src={img.default || img} alt={`Image ${index + 1}`} />
                        </div>
                    </li>
                ))}
                <img src={DHL} alt="DHL Image" />

            </ul>
            <ul>
                {images.map((img, index) => (
                    <li>
                        <div key={index} className="logo-cntner">
                            {/* Use img.default if the default key exists */}
                            <img src={img.default || img} alt={`Image ${index + 1}`} />
                        </div>
                    </li>
                ))}
                <img src={DHL} alt="DHL Image" />

            </ul>
        </div>
    );
}

export default LogosTicker;
