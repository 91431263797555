import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from './../assets/context/AuthContext';
import url from './url';

function Login() {
    const [loginData, setLoginData] = useState({
        Email: '',
        Password: ''
    });
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { login } = useAuth(); // Use the login method from AuthContext

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Handle login submission
    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');

        // Basic validation
        if (!loginData.Email || !loginData.Password) {
            setError('Please enter both email and password');
            return;
        }

        try {
            const response = await fetch(`${url}/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData)
            });

            const data = await response.json();

            if (!response.ok) {
                // Handle login errors
                setError(data.message || 'Login failed');
                return;
            }

            // Use the login method from AuthContext
            login(data.token, data.Cust_Id);

            // Redirect to dashboard or home page
            navigate('/Bookings');
        } catch (err) {
            console.error('Login error:', err);
            setError('An unexpected error occurred. Please try again.');
        }
    };

    return (
        <div className='login-wrapper center-center'>
            <div className="login-container">
                <h2>Sign in </h2>
                <form onSubmit={handleLogin} className="login-fields d-flex justify-content-center align-items-center flex-column">
                    {error && <div className="alert alert-danger" role="alert">{error}</div>}
                    
                    <input
                        type="email"
                        name="Email"
                        placeholder="Email"
                        value={loginData.Email}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="password"
                        name="Password"
                        placeholder="Password"
                        value={loginData.Password}
                        onChange={handleChange}
                        required
                    />
                    <div className="d-flex justify-content-between align-items-center mt-4 w-100">
                        <button type="submit" className="btn btn-smth">Sign in</button>
                        <p className="mb-0 forget-pass-link">Forget Password?</p>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login