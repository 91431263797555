import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        isLoggedIn: false,
        isAdmin: false,
        token: null,
        custId: null
    });

    // Check login status on initial load
    useEffect(() => {
        const token = localStorage.getItem('token');
        const isAdmin = localStorage.getItem('isAdmin') === 'true';
        const custId = localStorage.getItem('Cust_id');
        
        if (token) {
            setAuthState({
                isLoggedIn: true,
                isAdmin: isAdmin,
                token: token,
                custId: custId
            });
        }
    }, []);

    // Regular user login
    const login = (token, custId) => {
        localStorage.setItem('token', token);
        localStorage.setItem('Cust_id', custId);
        localStorage.setItem('isAdmin', 'false');
        
        setAuthState({
            isLoggedIn: true,
            isAdmin: false,
            token: token,
            custId: custId
        });
    };

    // Admin login
    const loginAdmin = (token, custId) => {
        localStorage.setItem('token', token);
        localStorage.setItem('Cust_id', custId);
        localStorage.setItem('isAdmin', 'true');
        
        setAuthState({
            isLoggedIn: true,
            isAdmin: true,
            token: token,
            custId: custId
        });
    };

    // Logout method
    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('Cust_id');
        localStorage.removeItem('isAdmin');
        
        setAuthState({
            isLoggedIn: false,
            isAdmin: false,
            token: null,
            custId: null
        });
    };

    return (
        <AuthContext.Provider value={{ 
            ...authState, 
            login, 
            loginAdmin, 
            logout 
        }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use auth context
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === null) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};