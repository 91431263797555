import React, { useEffect } from 'react';
import lottie from 'lottie-web';
import heroAnim from '../assets/hero-anim.json';


const HeroAnimation = () => {
  useEffect(() => {
    // Initialize Lottie animation
    const animationInstance = lottie.loadAnimation({
      container: document.querySelector('.hero-animation'), // The div container
      renderer: 'svg', // Render the animation in SVG format
      loop: true, // Set to true for infinite loop
      autoplay: true, // Start playing immediately
      animationData: heroAnim, // Your animation JSON file
    });

    return () => {
      animationInstance.destroy(); // Clean up on component unmount
    };
  }, []);

  return <div className="hero-animation" style={{ width: '100%', height: '130vh' }}></div>;
};

export default HeroAnimation;
