import logo from './logo.svg';
import './App.css';
import './utilities.css';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Layout from './components/Navbar'
import Home from './components/Home'
import CalQuote from './components/CalQuote';
import Quotes from './components/Quotes';
import SRdetails from './components/SRdetails';
import { QuoteProvider } from './assets/context/QuoteContext';
import Review from './components/Review';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Bookings from './components/Bookings';
import Dashboard from './components/Dashboard';
import Tracking from './components/Tracking';
import Login from './components/Login';
import { AuthProvider } from './assets/context/AuthContext';
import AdminLogin from './components/AdminLogin';
import Test from './components/Test';


const stripePromise = loadStripe('pk_test_51KgQiTIP2i6TE4N0U1iVUoO3vB5huS96akkF3F7KrcstGcp6nQMM9wndaTtHh0Kb1zdBvOAiecI8bKmXiuQ9pIpA00mBZmNlsa');

function App() {
  return (
    <AuthProvider>
    <Elements stripe={stripePromise}>
      <QuoteProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              {/* <Route path="about" element={<About />} /> */}
              <Route path="CalQuote" element={<CalQuote />} />
              <Route path="Quotes" element={<Quotes />} />
              <Route path="SRdetails" element={<SRdetails />} />
              <Route path="Review" element={<Review />} />
              <Route path="Bookings" element={<Bookings />} />
              <Route path="Dashboard" element={<Dashboard />} />
              <Route path="Tracking" element={<Tracking />} />
              <Route path="Login" element={<Login />} />
              <Route path="AdminLogin" element={<AdminLogin />} />
              <Route path="Test" element={<Test />} />
              
              
              {/* <Route path="signup" element={<Signup />} /> */}
            </Route>
          </Routes>
        </BrowserRouter>
      </QuoteProvider>
    </Elements>
    </AuthProvider>
  );
}

export default App;
