import React, { createContext, useState } from 'react';

// Create a context
export const QuoteContext = createContext();

// Create a provider component
export function QuoteProvider({ children }) {
    const [quoteData, setQuoteData] = useState(null);
    const [quoteRequestBody, setQuoteRequestBody] = useState(null);
    const [selectedQuote, setSelectedQuote] = useState(null);
    const [SRDetails, setSRDetails] = useState(null);
    const [formData, setFormData] = useState({
        pickupAddress: '',
        deliveryAddress: '',
        packageType: '',
        weight: '',
        goodsValue: '',
        dangerousGoods: '',
        pickupAddressType: '',
        deliveryAddressType: '',
        length: '',
        width: '',
        height: '',
        specialHandling: '',
        pickupPostcode: '',
        deliveryPostcode: '',
        pickupCity: '',
        deliveryCity: '',
        pickupState: '',
        deliveryState: '',
        originCountry: 'AU',
        destinationCountry: 'AU',
        palletStackable: null
    });


    // Function to save quotes
    const saveQuote = (data, requestBody, selectQuote) => {
        setQuoteData(data);
        setQuoteRequestBody(requestBody);
        setSelectedQuote(selectQuote);
    };

    // Function to save SR details
    const saveSRDetails = (srDetails) => {
        setSRDetails(srDetails); 
    };

    // Function to update formData
    const saveFormData = (newFormData) => {
        setFormData((prev) => ({ ...prev, ...newFormData })); // Merge new data with existing state
    };
    console.log("Form Data from the Context",formData)

    // Context value
    const contextValue = {
        quoteData,
        quoteRequestBody,
        selectedQuote,
        SRDetails,
        formData,
        saveQuote,
        saveSRDetails,
        saveFormData
    };

    return (
        <QuoteContext.Provider value={contextValue}>
            {children}
        </QuoteContext.Provider>
    );
}
