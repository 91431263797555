import React, { useState, useEffect, useContext } from 'react';
import { QuoteContext } from '../assets/context/QuoteContext';

function EditModal(props) {
    const { selectedQuote, saveSRDetails, SRDetails } = useContext(QuoteContext);
    const eSenderData = props.senderData;
    
    const [eFormData, eSetFormData] = useState({
        eFirstName: '',
        eLastName: '',
        eEmail: '',
        ePhone: '',
        eAddress1: '',
        eAddress2: '',
        ePickupInstructions: '',
    });

    useEffect(() => {
        eSetFormData({
            eFirstName: eSenderData.firstName || '',
            eLastName: eSenderData.lastName || '',
            eEmail: eSenderData.email || '',
            ePhone: eSenderData.phone || '',
            eAddress1: eSenderData.address1 || '',
            eAddress2: eSenderData.address2 || '',
            ePickupInstructions: eSenderData.pickupInstructions || '',
        });
    }, [eSenderData]);
    
    const eHandleChange = (e) => {
        const { name, value } = e.target;
        eSetFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Create updated sender data object
        const updatedSenderData = {
            firstName: eFormData.eFirstName,
            lastName: eFormData.eLastName,
            email: eFormData.eEmail,
            phone: eFormData.ePhone,
            address1: eFormData.eAddress1,
            address2: eFormData.eAddress2,
            pickupInstructions: eFormData.ePickupInstructions,
        };

        // Update SRDetails with new sender data while keeping receiver data unchanged
        saveSRDetails({
            senderFormData: updatedSenderData,
            receiverFormData: SRDetails.receiverFormData
        });

        // Close the modal
        const modal = document.querySelector('[data-bs-dismiss="modal"]');
        if (modal) {
            modal.click();
        }
    };

    return (
        <div>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Edit Sender Data</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="sender-form mt-5">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <input
                                                type="text"
                                                name="eFirstName"
                                                placeholder="First Name"
                                                value={eFormData.eFirstName}
                                                onChange={eHandleChange}
                                            />
                                        </div>
                                        <div className="col-lg-4">
                                            <input
                                                type="text"
                                                name="eLastName"
                                                placeholder="Last Name"
                                                value={eFormData.eLastName}
                                                onChange={eHandleChange}
                                            />
                                        </div>
                                        <div className="col-lg-4">
                                            <input
                                                type="email"
                                                name="eEmail"
                                                placeholder="Email"
                                                value={eFormData.eEmail}
                                                onChange={eHandleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-lg-4">
                                            <input
                                                type="tel"
                                                name="ePhone"
                                                placeholder="Sender's Phone"
                                                value={eFormData.ePhone}
                                                onChange={eHandleChange}
                                            />
                                        </div>
                                        <div className="col-lg-4">
                                            <input
                                                type="text"
                                                name="eAddress1"
                                                placeholder="Address Line 1"
                                                value={eFormData.eAddress1}
                                                onChange={eHandleChange}
                                            />
                                        </div>
                                        <div className="col-lg-4">
                                            <input
                                                type="text"
                                                name="eAddress2"
                                                placeholder="Address Line 2"
                                                value={eFormData.eAddress2}
                                                onChange={eHandleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-lg-12">
                                            <input
                                                type="text"
                                                name="ePickupInstructions"
                                                placeholder="Pickup instructions"
                                                className="w-100"
                                                value={eFormData.ePickupInstructions}
                                                onChange={eHandleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary">Save changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditModal;