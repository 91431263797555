import React from 'react'
// import { TypeInfo } from './../../node_modules/ts-node/dist/index.d';
import gps from '../assets/gps.svg'
import weight from '../assets/weight.svg'
import quantity from '../assets/quantity.svg'
import box from '../assets/box.svg'
import height from '../assets/height.svg'
import length from '../assets/length.svg'
import width from '../assets/width.svg'
import arrow from '../assets/arrow.svg'
import DHL from '../assets/CourierLogos/DHL.png'
// import { useLocation } from 'react-router-dom';


function DashBookingData({ bookingDetails }) {
    
    const booking = bookingDetails;

    if (!bookingDetails) {
        return <div></div>;
    }

    return (
        <div className='container module-close'>
            <div className="row">
                <div className="col-lg-12">
                    {/* <h2>Your Bookings</h2> */}
                    <div className="booked-booking px-4 py-4 mb-3 position-relative">
                        <div>
                            <div className="bkng-main-info d-flex align-items-center justify-content-between flex-wrap">
                                <span className='t-id'>Tracking ID: #{booking.OwcNumber}</span>
                                <span className='bkng-status'>{booking.Booking_Status}</span>
                                <span className='bkng-courier center-center'>Courier: {booking.Selected_Courier}</span>
                                <span className='bkng-EDD'>EDD: {booking.EDD}</span>
                                <span className='bkng-Price'>Paid: ${booking.Courier_Price} AUD</span>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className="col-lg-8">
                                <div className="bkng-info d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <img src={weight} alt="" />
                                        <span className='mb-0'>{booking.P_Weight} KG</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img style={{ width: '2rem' }} src={quantity} alt="" />
                                        <span className='mb-0'>{booking.P_Quantity} item</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img style={{ width: '1.5rem' }} src={box} alt="" />
                                        <span className='mb-0'>{booking.P_Type}</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img src={height} alt="" />
                                        <span className='mb-0'>{booking.D_Height} cm</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img src={length} alt="" />
                                        <span className='mb-0'>{booking.D_Length} cm</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img src={width} alt="" />
                                        <span className='mb-0'>{booking.D_Width} cm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-lg-6">
                                <div className="row">
                                    <div>
                                        <p className='mb-0'>
                                            <img style={{ width: '1rem', marginRight: '0.3rem' }} src={gps} alt="" />
                                            <span className='fw-600 fs-5'>From </span>
                                            <span style={{ marginLeft: '0.5rem' }} className='fw-500'>
                                                <small>{booking.From_Country}, {booking.From_City} {booking.From_P_Code}</small>
                                            </span>
                                        </p>
                                    </div>
                                    <div>
                                        <p><span className='fw-600'>Sender: </span>{booking.Sender_Fname} {booking.Sender_Lname}</p>
                                        <p><span className='fw-600'>Email: </span>{booking.Sender_Email}</p>
                                        <p><span className='fw-600'>Phone: </span>{booking.Sender_Phone}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row">
                                    <div>
                                        <p className='mb-0'>
                                            <img style={{ width: '1rem', marginRight: '0.3rem' }} src={gps} alt="" />
                                            <span className='fw-600 fs-5'>To </span>
                                            <span style={{ marginLeft: '0.5rem' }} className='fw-500'>
                                                <small>{booking.To_Country}, {booking.To_City} {booking.To_P_Code}</small>
                                            </span>
                                        </p>
                                    </div>
                                    <div>
                                        <p><span className='fw-600'>Recipient: </span>{booking.Rec_Fname} {booking.Rec_Lname}</p>
                                        <p><span className='fw-600'>Email: </span>{booking.Rec_Email}</p>
                                        <p><span className='fw-600'>Phone: </span>{booking.Rec_Phone}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="btn btn-close" />
                    </div>

                </div>
                <div className="col-lg-3">

                </div>
            </div>
        </div>
    )
}

export default DashBookingData
