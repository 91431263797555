import React from 'react'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import { useAuth } from '../assets/context/AuthContext';
import logo from '../assets/CourierWorld-Logo.svg'

function Navbar() {
    const { isLoggedIn, logout } = useAuth();
    const navigate = useNavigate();

    // Logout handler
    const handleLogout = () => {
        logout(); // Use the logout method from AuthContext
        navigate('/Login');
    };


    return (
        <>
            <div className='container'>
                <nav className="navbar navbar-expand-lg bg-body-tertiary">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#">
                            <img src={logo} alt="Logo" className="d-inline-block align-text-top" />
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    {isLoggedIn ? (
                                        <NavLink className="nav-link" to="/Bookings">My Bookings</NavLink>
                                    ) : (
                                        <NavLink className="nav-link" aria-current="page" to="/Home">Home</NavLink>
                                    )}
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Tracking">Tracking</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/About">About</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Help">Help</NavLink>
                                </li>
                                <div className="top-cta d-flex for-phone">
                                    {isLoggedIn ? (
                                        <>
                                            <NavLink to="/CalQuote" className="btn btn-smth mr-spc">Create Booking</NavLink>
                                            <button
                                                onClick={handleLogout}
                                                className="btn btn-stroked ml-2"
                                            >
                                                Logout
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <NavLink to="/CalQuote" className="btn btn-smth mr-spc">Book Now</NavLink>
                                            <NavLink to="/Login" className="btn btn-stroked ml-2">Login</NavLink>
                                        </>
                                    )}
                                </div>
                            </ul>
                        </div>
                        <div className="top-cta d-flex for-desktop">
                            {isLoggedIn ? (
                                <>
                                    <NavLink to="/CalQuote" className="btn btn-smth mr-spc">Create Booking</NavLink>
                                    <button
                                        onClick={handleLogout}
                                        className="btn btn-stroked ml-2"
                                    >
                                        Logout
                                    </button>
                                </>
                            ) : (
                                <>
                                    <NavLink to="/CalQuote" className="btn btn-smth mr-spc">Book Now</NavLink>
                                    <NavLink to="/Login" className="btn btn-stroked ml-2">Login</NavLink>
                                </>
                            )}
                        </div>
                    </div>
                </nav>
            </div>
            <Outlet />
        </>
    )
}

export default Navbar;