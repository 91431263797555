import React, { useState } from 'react';
import TrackShipmentModule from './TrackShipmentModule';
import url from './url';

function Tracking() {
    const [trackingNumber, setTrackingNumber] = useState('');
    const [trackingData, setTrackingData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchTrackingInfo = async (e) => {
        e.preventDefault();
        if (!trackingNumber.trim()) {
            setError('Please enter a tracking number');
            return;
        }

        setLoading(true);
        setError(null);
        console.log(`${url}/api/tracking/track/${trackingNumber}`)
        try {
            const response = await fetch(`${url}/api/tracking/track/${trackingNumber}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            const data = await response.json();
            console.log(data)
            if (!data.success) {
                throw new Error(data.message || 'Error fetching tracking information');
            }

            setTrackingData(data.data);
        } catch (err) {
            setError(err.message || 'Error fetching tracking information. Please try again.');
            console.error('Tracking fetch error:', err);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="container">
            <div className="d-flex align-items-center justify-content-center flex-column mt-5">
                <h1 className="display-5 fw-600 text-center">Track your Shipment</h1>
                <form onSubmit={fetchTrackingInfo} className="w-100 max-w-md center-center">
                    <div className="input-group mb-3 tracking-input-container mt-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Tracking Number"
                            value={trackingNumber}
                            onChange={(e) => setTrackingNumber(e.target.value)}
                            aria-label="Tracking number"
                        />
                        <div className="input-group-append">
                            <button 
                                className="btn btn-outline-secondary" 
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? 'Tracking...' : 'Track'}
                            </button>
                        </div>
                    </div>
                </form>

                {error && (
                    <div className="alert alert-danger mt-3" role="alert">
                        {error}
                    </div>
                )}

                {loading && (
                    <div className="mt-4 text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )}

                {trackingData && (
                    <div className="d-flex justify-content-center align-items-center mt-4 w-100">
                        <TrackShipmentModule trackingData={trackingData} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default Tracking
