import React, { useContext } from 'react';
import { QuoteContext } from '../assets/context/QuoteContext';
import { useNavigate } from 'react-router-dom';
import { getCarrierLogoById } from './logoConfig';
import EDD from '../assets/delivery-date.svg';
import CollectionDate from '../assets/collection-icon.svg';
import Warranty from '../assets/warranty.svg';
import QSidebar from './QSidebar';

const Quotes = () => {
    const { quoteData, quoteRequestBody, saveQuote } = useContext(QuoteContext);
    const data = quoteData;
    const requestBody = quoteRequestBody;
    const navigate = useNavigate();
    // console.log(quoteData.CarrierService.CarrierId)

    const handleProceed = (quote) => {
        const selectedQuoteDetails = {
            estimatedDelivery: quote.EstimatedDelivery,
            collectionDate: quote.NextDayPickup ? 'Collection by Tomorrow' : 'Standard Collection',
            warranty: quote.AppliedWarranty,
            carrierName: quote.CarrierService.CarrierName,
            carrierId: quote.CarrierService.CarrierId,
            carrierServiceId: quote.CarrierService.CarrierServiceId,

            price: (quote.PriceExTax + quote.PriceTax).toFixed(2),
        };
        saveQuote(data, requestBody, selectedQuoteDetails);
        navigate('/SRdetails');
    };

    return (
        <div className="container mt-5">
            <h2>Compare Quotes and Book Now</h2>
            <div className="row mt-4">
                <div className="col-lg-8">
                    <div className="quotes-container">
                        {data?.Quotes?.map((quote, index) => {
                            const carrierLogo = getCarrierLogoById(quote.CarrierService.CarrierId);
                            
                            return (
                                <div className="quote-container" key={index}>
                                    <div className="quote row">
                                        <div className="quote-ident col-lg-4">
                                            <span>Signature Required</span>
                                            <div className="quote-logo">
                                                {carrierLogo ? (
                                                    <img 
                                                        src={carrierLogo} 
                                                        alt={quote.CarrierService.CarrierName} 
                                                        className="carrier-logo"
                                                    />
                                                ) : (
                                                    <span className="no-logo">
                                                        {quote.CarrierService.CarrierName}
                                                    </span>
                                                )}
                                            </div>
                                            <span>{quote.CarrierService.CarrierName}</span>
                                        </div>
                                        <div className="quote-details col-lg-5">
                                            <div className="d-flex align-items-center">
                                                <img src={EDD} alt="Estimated Delivery" />
                                                <span className="mb-0">EDD {quote.EstimatedDelivery}</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <img src={CollectionDate} alt="Collection Date" />
                                                <span className="mb-0">
                                                    {quote.NextDayPickup ? 'Collection by Tomorrow' : 'Standard Collection'}
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <img src={Warranty} alt="Warranty" />
                                                <span className="mb-0">${quote.AppliedWarranty?.toFixed(2)} included</span>
                                            </div>
                                        </div>
                                        <div className="price col-lg-3">
                                            <div>
                                                <div>
                                                    <span className="amount">
                                                        ${(quote.PriceExTax + quote.PriceTax).toFixed(2)}
                                                    </span>
                                                    <span className="tax-tag">Inc GST</span>
                                                </div>
                                                <button 
                                                    className="btn btn-smth w-100" 
                                                    onClick={() => handleProceed(quote)}
                                                >
                                                    Proceed
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="col-lg-4">
                    <QSidebar />
                </div>
            </div>
        </div>
    );
};

export default Quotes;