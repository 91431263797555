import React from 'react'
import gps from '../assets/gps.svg'
import weight from '../assets/weight.svg'
import quantity from '../assets/quantity.svg'
import box from '../assets/box.svg'
import height from '../assets/height.svg'
import length from '../assets/length.svg'
import width from '../assets/width.svg'
import arrow from '../assets/arrow.svg'
import DHL from '../assets/CourierLogos/DHL.png'

function TrackShipmentModule(props) {
    const tD = props.trackingData;

    return (
        <div className='container mt-5 booking-shipment-module'>
            <div className="row center-center">
                <div className="">
                    <div className="booked-booking px-4 py-4">
                        <div>
                            <div className="bkng-main-info d-flex align-items-center justify-content-between">
                                <span className='t-id'>Tracking ID: {tD.OwcNumber}</span>
                                <span className='bkng-status'>{tD.Tracking.OneWorldStatusCode}</span>
                                {/* <span className='bkng-courier center-center'>Courier: {tD.CarrierService.CarrierName} </span> */}
                                {/* <span className='bkng-EDD'>EDD: {tD.EstimatedDelivery}</span> */}
                            </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-4">
                            <div>
                                <p className='mb-0'>
                                    <img style={{ width: '1rem', marginRight: '0.3rem' }} src={gps} alt="" />
                                    <span className='fw-600 fs-5'>From </span>
                                    <span style={{ marginLeft: '0.5rem' }} className='fw-500'>
                                        <small>{tD.FromAddress.CountryCode} {tD.FromAddress.City} {tD.FromAddress.Postcode}</small>
                                    </span>
                                </p>
                            </div>
                            <div>
                                <p className='mb-0'>
                                    <img style={{ width: '1rem', marginRight: '0.3rem' }} src={gps} alt="" />
                                    <span className='fw-600 fs-5'>To </span>
                                    <span style={{ marginLeft: '0.5rem' }} className='fw-500'>
                                        <small>{tD.ToAddress.CountryCode} {tD.ToAddress.City} {tD.ToAddress.Postcode}</small>
                                    </span>
                                </p>
                            </div>
                        </div>

                        <div className="bkng-progress-bar mt-5">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{ width: "30%" }} scope="col">Date & Time</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tD.Tracking.TrackingLines.map((track, index) => (
                                        <tr key={index}>
                                            <td>{track.CarrierDateTime}</td>
                                            <td>
                                                <span className='fw-500'>{track.CarrierStatusDescription}</span>
                                                <br />{track.DepotName}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/* Show POD information if available */}
                            {/* {tD.Tracking.Pods && tD.Tracking.Pods.length > 0 && (
                                <div className="pod-info mt-4">
                                    <h5>Proof of Delivery</h5>
                                    {tD.Tracking.Pods.map((pod, index) => (
                                        <div key={index} className="pod-details">
                                            <p>
                                                <strong>Signed By:</strong> {pod.SignedBy}<br />
                                                <strong>Date & Time:</strong> {pod.SignedDateTime}<br />
                                                <strong>Type:</strong> {pod.DeliveryImageType}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            )} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrackShipmentModule