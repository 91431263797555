// src/assets/config/logoConfig.js

// Import all carrier logos
import DHL from '../assets/CourierLogos/DHL.png'
import FedEx from '../assets/CourierLogos/FedEx.png'
import UPS from '../assets/CourierLogos/UPS.png'
import aramex from '../assets/CourierLogos/aramex.png'
import CouriersPlease from '../assets/CourierLogos/CouriersPlease.png'
import AlliedExpress from '../assets/CourierLogos/Allied-Express.png'
import DirectCourier from '../assets/CourierLogos/direct-courier.png'
import TNT from '../assets/CourierLogos/TNT.png'
import StarTrack from '../assets/CourierLogos/StarTrack.png'
import AussieFast from '../assets/CourierLogos/aussie-fast.png'
import Sendle from '../assets/CourierLogos/Sendle.png'
import CRL from '../assets/CourierLogos/CRL.png'
import RT from '../assets/CourierLogos/RT.png'
import Sadliers from '../assets/CourierLogos/sadleirs.png'
import GoldTiger from '../assets/CourierLogos/Gold-Tiger.png'
import PalmTrans from '../assets/CourierLogos/Palm Trans.png'
import HiTransExpress from '../assets/CourierLogos/HiTransExpress.png'
import Northline from '../assets/CourierLogos/Northline.png'
import AUZCC from '../assets/CourierLogos/AUZ-Country-Carriers.png'
import GKRTransport from '../assets/CourierLogos/GKR Transport.png'
import Centurion from '../assets/CourierLogos/centurion.png'
import MFD from '../assets/CourierLogos/MFD-LineHaul.png'
import TransFreight from '../assets/CourierLogos/TransFreight.png'
import Followmont from '../assets/CourierLogos/Followmont.png'
import CapitalTransport from '../assets/CourierLogos/capital-transport.png'
import GMK from '../assets/CourierLogos/GMK-Logistics.png'



export const carrierLogos = {
    // Using hypothetical carrier IDs - replace with your actual carrier IDs
    18: DHL,
    48: FedEx,
    3: UPS,
    49: UPS,
    2: TNT,
    25: aramex,
    45: StarTrack,
    44: CouriersPlease,
    5: AlliedExpress,
    6: AlliedExpress,
    9: DirectCourier,
    33: AussieFast,
    26: Sendle,
    100004: RT,
    30: Sadliers,
    37: Sadliers,
    29: GoldTiger,
    false:CRL,
    false: PalmTrans,
    20: HiTransExpress,
    4: Northline,
    36: Northline,
    41: AUZCC,
    35: GKRTransport,
    44: Centurion,
    100010: MFD,
    31: TransFreight,
    38: TransFreight,
    19: Followmont,
    24:CapitalTransport,
    51:GMK

};

// Helper function to get logo by carrier ID
export const getCarrierLogoById = (carrierId) => {
    console.log(carrierId)
    return carrierLogos[carrierId] || null;
};