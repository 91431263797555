import React, { useContext, useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from "react-router-dom";
import QSidebar from './QSidebar';
import gps from '../assets/gps.svg';
import { QuoteContext } from '../assets/context/QuoteContext';
import EDDDark from '../assets/EDD-dark.svg';
import CollectionDateDark from '../assets/Box-dark.svg';
import WarrantyDark from '../assets/warranty-dark.svg';
import EditModal from './EditModal';
import ReceiverEditModal from './ReceiverEditModal';
import { useAuth } from './../assets/context/AuthContext';
import url from './url';

function Review() {
    const navigate = useNavigate();
    const { SRDetails, quoteRequestBody, quoteData, selectedQuote, formData } = useContext(QuoteContext);

    console.log("This is form Data", formData)
    console.log(useContext(QuoteContext))
    const [senderData, setSenderData] = useState(SRDetails.senderFormData);
    const [receiverData, setReceiverData] = useState(SRDetails.receiverFormData);
    const [paymentStatus, setPaymentStatus] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    console.log("This is quotebody", quoteRequestBody)


    useEffect(() => {
        setSenderData(SRDetails.senderFormData);
    }, [SRDetails.senderFormData]);

    // Handle sender data updates from modal
    const handleSenderUpdate = (updatedSenderData) => {
        setSenderData(updatedSenderData);
    };

    useEffect(() => {
        setSenderData(SRDetails.receiverFormData);
    }, [SRDetails.receiverFormData]);

    // Handle sender data updates from modal
    const handleReceiverUpdate = (updatedReceiverData) => {
        setReceiverData(updatedReceiverData);
    };
    // Card element styling
    const cardStyle = {
        style: {
            base: {
                fontSize: '16px',
                color: '#32325d',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        },
    };

    // handling user account Creation 

    const [accountData, setAccountData] = useState({
        Name: '',
        Email: '',
        Password: '',
    });

    const { login } = useAuth()
    const handleAccountChange = (e) => {
        const { name, value } = e.target;
        setAccountData({ ...accountData, [name]: value });
    };

    // Check if user is already logged in
    const isLoggedIn = localStorage.getItem('token') !== null;

    const handleAccountCreation = async () => {
        // Check if user is already logged in
        const existingToken = localStorage.getItem('token');
        const existingCustId = localStorage.getItem('Cust_id');

        // If already logged in, return existing customer ID
        if (existingToken && existingCustId) {
            return existingCustId;
        }

        try {
            const response = await fetch(`${url}/api/register`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(accountData),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Failed to create account');
            }

            console.log("This is from Signup Review fronted", data)
            // Use the login method from AuthContext
            login(data.token, data.Cust_Id);

            console.log('Account created successfully:', data);
            return data.Cust_Id;
        } catch (error) {
            console.error('Account creation error:', error);
            throw error;
        }
    };



    const sendBookingData = async (Cust_id) => {
        try {

            const bookingData = {
                SRDetails,          // Contains sender/receiver details
                quoteRequestBody,   // Contains request-specific details
                quoteData,          // Contains all quote details
                selectedQuote,      // Contains the selected quote for booking
                Cust_id,
                formData
            };

            const response = await fetch(`${url}/api/booking`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(bookingData),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Failed to create account');
            }

            console.log('Account created successfully:', data);
            return data.bookingId; // Return the customer ID for further use
        } catch (error) {
            console.error('Account creation error:', error);
            throw error;
        }
    };

    const handlePayment = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsProcessing(true);
        setPaymentStatus('Processing payment...');

        try {
            // Step 1: Create Account
            const Cust_id = await handleAccountCreation();
            const bookingId = await sendBookingData(Cust_id);


            // Step 2: Process Payment
            const amount = selectedQuote.price; // Replace with actual amount from your quote
            const response = await fetch(`${url}/api/create-payment-intent`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ amount }),
            });

            if (!response.ok) {
                throw new Error('Failed to create payment intent');
            }

            const { clientSecret } = await response.json();

            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: senderData.firstName,
                        email: senderData.email,
                    },
                },
            });

            if (result.error) {
                setPaymentStatus(`Payment failed: ${result.error.message}`);
            } else if (result.paymentIntent.status === 'succeeded') {
                const updateResponse = await fetch(`${url}/api/booking/update-payment-status/${bookingId}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        paymentIntentId: result.paymentIntent.id
                    }),
                });

                if (!updateResponse.ok) {
                    throw new Error('Failed to update payment status');
                }

                const bookingResponse = await fetch(`${url}/api/booking/create-booking`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        bookingId: bookingId, // Your database booking ID
                        SRDetails,
                        quoteRequestBody,
                        selectedQuote
                    })
                });

                if (bookingResponse.ok) {
                    const result = await bookingResponse.json();
                    // Handle success - maybe redirect to confirmation page
                    // navigate('/Bookings');
                    navigate('/Bookings', { state: { bookingData: result } });
                    console.log(result)
                    console.log("Booking Created")
                } else {
                    // Handle error
                    const error = await bookingResponse.json();
                    console.log(error.message);
                }



                setPaymentStatus('Payment successful! Redirecting...');
                return true;





                // Handle post-payment actions, e.g., creating a booking record
            }
        } catch (error) {
            console.error('Payment Error:', error);
            setPaymentStatus(`Error: ${error.message}`);
        }

        setIsProcessing(false);
    };


    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-lg-9">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <h2>Review & Pay</h2>
                        <div className="cesseninfo cesseninfo-review">
                            <div className="d-flex justify-content-around py-2">
                                <div className="d-flex align-items-center justify-content-center mr-spc">
                                    <div className="edd-icon">
                                        <img src={EDDDark} alt="" />
                                    </div>
                                    <span>EDD {selectedQuote.estimatedDelivery}</span>
                                </div>
                                <div className="d-flex align-items-center justify-content-center mr-spc">
                                    <div className="edd-icon">
                                        <img src={CollectionDateDark} alt="" />
                                    </div>
                                    <span>{selectedQuote.collectionDate}</span>
                                </div>
                                <div className="d-flex align-items-center justify-content-center mr-spc">
                                    <div className="edd-icon">
                                        <img src={WarrantyDark} alt="" />
                                    </div>
                                    <span>${selectedQuote.warranty} of warranty Included</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-lg-3">

                </div>
            </div>

            <div className="row mt-4">
                <div className="col-lg-9">
                    <div className="details-container">
                        <div className="from-details">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div>
                                        <p >
                                            <img style={{ width: '1rem', marginRight: '0.3rem' }} src={gps} alt="" />
                                            <span className='fw-600 fs-5'>From </span>
                                            <span style={{ marginLeft: '0.5rem' }} className='fw-500'><small> Australia, Sydney 2000 NSW</small></span>
                                        </p>
                                    </div>
                                    <div>
                                        <div>
                                            <p><span className="address-line-1">{senderData.address1}</span></p>
                                        </div>
                                        <div>
                                            <p><span className="address-line-2">{senderData.address2}</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div>
                                        <p><span>{senderData.firstName}</span></p>
                                    </div>
                                    <div>
                                        <p><span>{senderData.email}</span></p>
                                    </div>
                                    <div>
                                        <p><span>{senderData.phone}</span></p>
                                    </div>

                                </div>
                                <div className="col-lg-4">
                                    <div>
                                        <p><span className='fw-600'>Pickup Time:</span> <span>Oct 24, 10:30am</span></p>
                                    </div>
                                    <div>
                                        <p><span className='fw-600'>Addr type:</span> <span>Residentail</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="pickup-ins-edit-btn mt-1 d-flex justify-content-between align-items-center flex-wrap">
                                <div><span className='fw-600 mt-2'>Pickup Instructions:</span> <span>{senderData.pickupInstructions}</span></div>
                                <button className="btn btn-stroked mt-2 btn" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal">Edit Details</button>
                            </div>
                            <EditModal senderData={senderData}
                                onUpdateSender={handleSenderUpdate}
                            />


                        </div>
                    </div>
                    <div className="details-container">
                        <div className="reciever-details">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div>
                                        <p >
                                            <img style={{ width: '1rem', marginRight: '0.3rem' }} src={gps} alt="" />
                                            <span className='fw-600 fs-5'>To </span>
                                            <span style={{ marginLeft: '0.5rem' }} className='fw-500'><small> Australia, Melbourne 2000 NSW</small></span>
                                        </p>
                                    </div>
                                    <div>
                                        <div>
                                            <p><span className="address-line-1">{receiverData.address1}</span></p>
                                        </div>
                                        <div>
                                            <p><span className="address-line-2">{receiverData.address2}</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div>
                                        <p><span>{receiverData.firstName}</span></p>
                                    </div>
                                    <div>
                                        <p><span>{receiverData.lastName}</span></p>
                                    </div>
                                    <div>
                                        <p><span>{receiverData.phone}</span></p>
                                    </div>

                                </div>
                                <div className="col-lg-4">
                                    <div>
                                        <p><span className='fw-600'>Delivery Time:</span> <span>Oct 29, 10:30am</span></p>
                                    </div>
                                    <div>
                                        <p><span className='fw-600'>Addr type:</span> <span>Residentail</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="pickup-ins-edit-btn mt-1 d-flex justify-content-between align-items-center flex-wrap">
                                <div><span className='fw-600 mt-2'>Delivery Instructions:</span> <span>{receiverData.pickupInstructions}</span></div>
                                <button className="btn btn-stroked mt-2" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal2">Edit Details</button>
                            </div>
                            <ReceiverEditModal receiverData={receiverData}
                                onUpdateReceiver={handleReceiverUpdate}
                            />
                        </div>
                    </div>
                    {!isLoggedIn && (
                        <div className="account-signup">
                            <div className="acc-reg">
                                <div className="d-flex align-items-center justify-content-between">
                                    <input
                                        type="text"
                                        name="Name"
                                        placeholder="Username"
                                        value={accountData.Name}
                                        onChange={handleAccountChange}
                                    />
                                    <input
                                        type="email"
                                        name="Email"
                                        placeholder="Email"
                                        value={accountData.Email}
                                        onChange={handleAccountChange}
                                    />
                                    <input
                                        type="password"
                                        name="Password"
                                        placeholder="Password"
                                        value={accountData.Password}
                                        onChange={handleAccountChange}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="payment-section mt-4 p-4 border rounded">
                        <h4>Payment Details</h4>
                        <form onSubmit={handlePayment}>
                            <div className="card-element-container p-3 border rounded mb-3">
                                <CardElement options={cardStyle} />
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isProcessing || !stripe}
                            >
                                {isProcessing ? 'Processing...' : 'Pay Now'}
                            </button>
                            {paymentStatus && (
                                <div className={`mt-3 alert ${paymentStatus.includes('succeeded') ? 'alert-success' :
                                    paymentStatus.includes('Processing') ? 'alert-info' :
                                        'alert-danger'
                                    }`}>
                                    {paymentStatus}
                                </div>
                            )}
                        </form>
                    </div>

                </div>
                <div className="col-lg-3 col-rev-992">
                    <QSidebar />
                </div>
            </div>
        </div>
    )
}

export default Review
