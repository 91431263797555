import React, { useState, useEffect } from 'react'
import gps from '../assets/gps.svg'
import weight from '../assets/weight.svg'
import quantity from '../assets/quantity.svg'
import box from '../assets/box.svg'
import height from '../assets/height.svg'
import length from '../assets/length.svg'
import width from '../assets/width.svg'
import DHL from '../assets/CourierLogos/DHL.png'
import url from './url'

function Bookings() {
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const token = localStorage.getItem('token');
                const Cust_Id = localStorage.getItem('Cust_Id');

                console.log('Token:', token);
                console.log('Cust_Id:', Cust_Id);

    
                if (!token) {
                    console.error('No token found');
                    setError('Not authenticated');
                    setLoading(false);
                    return;
                }
    
                const response = await fetch(`${url}/api/booking/fetch-bookings`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'Cust_Id':Cust_Id
                    }
                });
    
                const data = await response.json();
    
                if (!response.ok) {
                    throw new Error(data.message || 'Failed to fetch bookings');
                }
    
                setBookings(data.bookings);
                setLoading(false);
            } catch (err) {
                console.error('Fetch bookings error:', err);
                setError(err.message);
                setLoading(false);
            }
        };
    
        fetchBookings();
    }, []); // You might want to add dependencies if needed

    if (loading) return <div>Loading bookings...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className='container mt-5'>
            <div className="row">
                <div className="col-lg-9">
                    <h2 className='mb-4'>Your Bookings</h2>
                    {bookings.map((booking, index) => (
                        <div key={index} className="booked-booking px-4 py-4 mb-3">
                            <div>
                                <div className="bkng-main-info d-flex align-items-center justify-content-between flex-wrap">
                                    <span className='t-id'>Tracking ID: #{booking.OwcNumber}</span>
                                    <span className='bkng-status'>{booking.Booking_Status}</span>
                                    <span className='bkng-courier center-center'>Courier: {booking.Selected_Courier}</span>
                                    <span className='bkng-EDD'>EDD: {booking.EDD}</span>
                                    <span className='bkng-Price'>Paid: ${booking.Courier_Price} AUD</span>
                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className="col-lg-8">
                                    <div className="bkng-info d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <img src={weight} alt="" />
                                            <span className='mb-0'>{booking.P_Weight} KG</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <img style={{ width: '2rem' }} src={quantity} alt="" />
                                            <span className='mb-0'>{booking.P_Quantity} item</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <img style={{ width: '1.5rem' }} src={box} alt="" />
                                            <span className='mb-0'>{booking.P_Type}</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <img src={height} alt="" />
                                            <span className='mb-0'>{booking.D_Height} cm</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <img src={length} alt="" />
                                            <span className='mb-0'>{booking.D_Length} cm</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <img src={width} alt="" />
                                            <span className='mb-0'>{booking.D_Width} cm</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div>
                                            <p className='mb-0'>
                                                <img style={{ width: '1rem', marginRight: '0.3rem' }} src={gps} alt="" />
                                                <span className='fw-600 fs-5'>From </span>
                                                <span style={{ marginLeft: '0.5rem' }} className='fw-500'>
                                                    <small>{booking.From_Country}, {booking.From_City} {booking.From_P_Code}</small>
                                                </span>
                                            </p>
                                        </div>
                                        <div>
                                            <p><span className='fw-600'>Sender: </span>{booking.Sender_Fname} {booking.Sender_Lname}</p>
                                            <p><span className='fw-600'>Email: </span>{booking.Sender_Email}</p>
                                            <p><span className='fw-600'>Phone: </span>{booking.Sender_Phone}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div>
                                            <p className='mb-0'>
                                                <img style={{ width: '1rem', marginRight: '0.3rem' }} src={gps} alt="" />
                                                <span className='fw-600 fs-5'>To </span>
                                                <span style={{ marginLeft: '0.5rem' }} className='fw-500'>
                                                    <small>{booking.To_Country}, {booking.To_City} {booking.To_P_Code}</small>
                                                </span>
                                            </p>
                                        </div>
                                        <div>
                                            <p><span className='fw-600'>Recipient: </span>{booking.Rec_Fname} {booking.Rec_Lname}</p>
                                            <p><span className='fw-600'>Email: </span>{booking.Rec_Email}</p>
                                            <p><span className='fw-600'>Phone: </span>{booking.Rec_Phone}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Bookings