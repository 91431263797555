import React, { useState } from 'react';
import url from './url';

function Test() {
    const [message, setMessage] = useState(''); // State to hold the backend message

    const getResponse = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${url}/api/test`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();
            setMessage(data.message); // Update the message in the state
        } catch (err) {
            console.error('This is the error:', err);
            setMessage('Failed to fetch the message from the backend'); // Set error message
        }
    };

    return (
        <div className='container'>
            <p>Click this button to check the Response from the backend</p>
            <button className="btn btn-primary mb-5" onClick={getResponse}>
                Test
            </button>
            {message && ( // Conditionally render the message
                <div className="response-message">
                    <p>{message}</p>
                </div>
            )}
        </div>
    );
}

export default Test;
