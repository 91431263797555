import React, { useContext } from 'react'
import gps from '../assets/gps.svg'
import weight from '../assets/weight.svg'
import quantity from '../assets/quantity.svg'
import box from '../assets/box.svg'
import height from '../assets/height.svg'
import length from '../assets/length.svg'
import width from '../assets/width.svg'
import arrow from '../assets/arrow.svg'
import dBox from '../assets/dimension-box.svg'
import DHL from '../assets/CourierLogos/DHL.png'
import { getCarrierLogoById } from './logoConfig';
import { QuoteContext } from '../assets/context/QuoteContext';


function QSidebar(props) {
    const { quoteRequestBody, selectedQuote } = useContext(QuoteContext);
    let rQ = quoteRequestBody
    console.log(quoteRequestBody)
    if (selectedQuote) {
        console.log(selectedQuote)
    } else {
        console.log("no data")
    }

    // const { rQ } = props
    // console.log(rQ.FromAddress?.City)
    return (
        <>
            <div className="quote-detail-sidebar">
                <div className="loctions-info qds-spacing">
                    <div className=" row">
                        <div className="col-lg-5">
                            <div>
                                <img src={gps} alt="" />
                                <span className='fw-600'>From</span>
                            </div>
                            <p className='mb-0'>{rQ?.FromAddress?.City} {rQ?.FromAddress?.Postcode}</p>
                        </div>
                        <div className="col-lg-2">
                            <div className="arrow-conntector">
                                <img src={arrow} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div>
                                <img src={gps} alt="" />
                                <span className='fw-600'>To</span>
                            </div>
                            <p className='mb-0'>{rQ?.ToAddress?.City} {rQ?.ToAddress?.Postcode}</p>
                        </div>
                    </div>
                </div>
                <div className="weight-info qds-spacing">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <img src={weight} alt="" />
                            <span className='mb-0'>{rQ?.Items[0]?.Weight} KG</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <img style={{ width: '2rem' }} src={quantity} alt="" />
                            <span className='mb-0'>{rQ?.Items[0]?.Quantity} item</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <img style={{ width: '1.5rem' }} src={box} alt="" />
                            <span className='mb-0'>{rQ?.Items[0]?.PackageType}</span>
                        </div>
                    </div>
                </div>
                <div className="dimensions qds-spacing">
                    <div className="row">
                        <div className="col-lg-8">
                            <span className='fw-600 mb-1 d-block'>Dimensions</span>
                            <div className="d-flex justify-content-between ">
                                <div className="d-flex align-items-center">
                                    <img src={height} alt="" />
                                    <span className='mb-0'>{rQ?.Items[0]?.Height} cm</span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <img src={length} alt="" />
                                    <span className='mb-0'>{rQ?.Items[0]?.Length} cm</span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <img src={width} alt="" />
                                    <span className='mb-0'>{rQ?.Items[0]?.Width} cm</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="dBox">
                                <img src={dBox} alt="" />
                            </div>
                        </div>
                    </div>
                    <button className='btn btn-smth mt-3'>Edit Quote</button>

                </div>
            </div>
            {selectedQuote && (
                <div className='sidebar-carrier-Details mt-5'>
                    {/* Selected Carrier Logo */}
                    <div className='d-flex align-items'>
                        <div className="carrier-name">
                            <h5 className='mb-0'>Courier: {selectedQuote.carrierName}</h5>
                            <p className="mb-0 small">Signature Requied</p>
                        </div>
                        <div className="sc-logo">
                            <img src={getCarrierLogoById(selectedQuote.carrierId)} alt="" />
                        </div>
                    </div>
                    <div className="sc-price mt-3">
                        <h4 className='fw-600'>${selectedQuote.price} AUD</h4>
                    </div>
                </div>
            )}
        </>
    )
}

export default QSidebar
