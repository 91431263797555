import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from './../assets/context/AuthContext';
import url from './url';

function AdminLogin() {
    const [loginData, setLoginData] = useState({
        Admin_Email: '',
        Admin_Password: ''
    });
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { loginAdmin } = useAuth(); // Use a new method for admin login

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Handle admin login submission
    const handleAdminLogin = async (e) => {
        e.preventDefault();
        setError('');

        // Basic validation
        if (!loginData.Admin_Email || !loginData.Admin_Password) {
            setError('Please enter both email and password');
            return;
        }

        try {
            const response = await fetch(`${url}/api/admin-login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData)
            });

            const data = await response.json();

            if (!response.ok) {
                // Handle login errors
                setError(data.message || 'Admin Login failed');
                return;
            }

            // Use the admin login method from AuthContext
            loginAdmin(data.token, data.Admin_Id);

            // Redirect to dashboard
            navigate('/Dashboard');
        } catch (err) {
            console.error('Admin Login error:', err);
            setError('An unexpected error occurred. Please try again.');
        }
    };

    return (
        <div className='login-wrapper center-center admin-login-container'>
            <div className="login-container">
                <h2>This Admin Admin Login</h2>
                <form onSubmit={handleAdminLogin} className="login-fields d-flex justify-content-center align-items-center flex-column">
                    {error && <div className="alert alert-danger" role="alert">{error}</div>}
                    
                    <input
                        type="email"
                        name="Admin_Email"
                        placeholder="Admin Email"
                        value={loginData.Admin_Email}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="password"
                        name="Admin_Password"
                        placeholder="Password"
                        value={loginData.Admin_Password}
                        onChange={handleChange}
                        required
                    />
                    <div className="d-flex justify-content-between align-items-center mt-4 w-100">
                        <button type="submit" className="btn btn-smth">Sign in</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AdminLogin;
