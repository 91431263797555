import React, { useState, useEffect } from 'react';
import { useAuth } from './../assets/context/AuthContext';
import { useNavigate } from 'react-router-dom'; // Correct import
import DashBookingData from './DashBookingData';
import url from './url';

function Dashboard() {

    const navigate = useNavigate();
    const { isAdmin, user } = useAuth();

    const [dashboardSummary, setDashboardSummary] = useState({
        totalCustomers: 0,
        totalBookings: 0,
        totalRevenue: 0
    });

    const [bookingsData, setBookingsData] = useState({
        bookings: [],
        pagination: {
            currentPage: 1,
            totalPages: 0,
            totalBookings: 0,
            limit: 10
        }
    });

    const [markup, setMarkup] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);


    // New state for booking details
    const [selectedBookingDetails, setSelectedBookingDetails] = useState(null);

    // Check admin status first
    useEffect(() => {
        if (!isAdmin) {
            navigate('/AdminLogin');
        }
    }, [isAdmin, navigate]);

    // Fetch dashboard data
    useEffect(() => {
        if (isAdmin) {
            fetchDashboardData();
        }
    }, [isAdmin]);

    // Fetch dashboard summary and bookings
    const fetchDashboardData = async (page = 1) => {
        try {
            setIsLoading(true);

            // Fetch dashboard summary
            const summaryResponse = await fetch(`${url}/api/dash-stats/dashboard-summary`);
            if (!summaryResponse.ok) {
                throw new Error('Failed to fetch dashboard summary');
            }
            const summaryData = await summaryResponse.json();
            setDashboardSummary(summaryData);

            // Fetch bookings with pagination
            const bookingsResponse = await fetch(`${url}/api/dash-stats/bookings?page=${page}`);
            if (!bookingsResponse.ok) {
                throw new Error('Failed to fetch bookings');
            }
            const bookingsData = await bookingsResponse.json();
            setBookingsData(bookingsData);

            const markupResponse = await fetch(`${url}/api/dash-stats/markup`);
            if (!markupResponse.ok) {
                throw new Error('Failed to fetch markup');
            }
            const markupData = await markupResponse.json();
            setMarkup(markupData.markup);

            setIsLoading(false);
        } catch (err) {
            setError(err.message);
            setIsLoading(false);
            console.error(err);
        }
    };

    // Handle page change
    const handlePageChange = (newPage) => {
        fetchDashboardData(newPage);
    };

    // Handle markup change
    const handleMarkupChange = async () => {
        try {
            const response = await fetch(`${url}/api/dash-stats/markup`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newMarkup: markup })
            });

            if (!response.ok) {
                throw new Error('Failed to update markup');
            }

            const result = await response.json();
            alert(result.message);
            // Refresh data to confirm markup update
            fetchDashboardData(bookingsData.pagination.currentPage);
        } catch (err) {
            setError(err.message);
            console.error(err);
        }
    };

    // Handle booking status change
    const handleStatusChange = async (bookingId, currentStatus) => {
        try {
            const newStatus = currentStatus === 'Booked' ? 'Pending' : 'Booked';

            const response = await fetch(`${url}/api/dash-stats/bookings/${bookingId}/status`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newStatus })
            });

            if (!response.ok) {
                throw new Error('Failed to update booking status');
            }

            // Refresh current page data
            fetchDashboardData(bookingsData.pagination.currentPage);
        } catch (err) {
            setError(err.message);
            console.error(err);
        }
    };

    // If not an admin, render unauthorized access
    if (!isAdmin) {
        return <div>Unauthorized Access</div>;
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const fetchBookingDetails = async (bookingId) => {
        try {
            const response = await fetch(`${url}/api/dash-stats/bookings/${bookingId}/details`);
            if (!response.ok) {
                throw new Error('Failed to fetch booking details');
            }
            const bookingDetails = await response.json();
            setSelectedBookingDetails(bookingDetails);
            console.log(selectedBookingDetails)
        } catch (err) {
            setError(err.message);
            console.error(err);
        }
    };


    return (
        <div>
            <div className="container mt-5">
                {/* Dashboard Boxes */}
                <div className="row dash-boxes-row">
                    <div className="col-lg-3">
                        <div className="dash-data-box">
                            <h4>Bookings Markup</h4>
                            <div className='d-flex'>
                                <input
                                    type="number"
                                    className='markup-input form-control'
                                    value={markup}
                                    onChange={(e) => setMarkup(e.target.value)}
                                    min="0"
                                    max="100"
                                    step="0.1"
                                />
                                <button
                                    type="button"
                                    className="btn btn-smth ms-2"
                                    onClick={handleMarkupChange}
                                >
                                    change
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="dash-data-box">
                            <h4>Total Customers</h4>
                            <span>{dashboardSummary.totalCustomers}</span>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="dash-data-box">
                            <h4>Total Bookings</h4>
                            <span>{dashboardSummary.totalBookings}</span>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="dash-data-box">
                            <h4>Total Revenue</h4>
                            <span>${dashboardSummary.totalRevenue.toFixed(2)}</span>
                        </div>
                    </div>
                </div>

                {/* Bookings Table */}
                <div className="table-container mt-5">
                    <div className="fetched-booking">
                        <DashBookingData bookingDetails={selectedBookingDetails} />
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Customer ID</th>
                                <th scope="col">Customer Name</th>
                                <th scope="col">Booking ID</th>
                                <th scope="col">P/C Status</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bookingsData.bookings.map((booking) => (
                                <tr key={booking.Booking_ID}>
                                    <td>{booking.Cust_ID}</td>
                                    <td>{booking.Customer_Name}</td>
                                    <td>{booking.Booking_ID}</td>
                                    <td>{booking.Payment_Status}</td>
                                    <td>{booking.Booking_Status}</td>
                                    <td>
                                        <button
                                            onClick={() => handleStatusChange(
                                                booking.Booking_ID,
                                                booking.Booking_Status
                                            )}
                                            className="btn btn-sm btn-primary mr-spc"
                                        >
                                            CS
                                        </button>
                                        <button
                                            onClick={() => fetchBookingDetails(booking.Booking_ID)}
                                            className="btn btn-sm btn-primary"
                                        >
                                            Detail
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Pagination Controls */}
                    <div className="pagination d-flex justify-content-center">
                        <button
                            className="btn btn-secondary me-2"
                            onClick={() => handlePageChange(bookingsData.pagination.currentPage - 1)}
                            disabled={bookingsData.pagination.currentPage === 1}
                        >
                            Previous
                        </button>
                        <span className="align-self-center">
                            Page {bookingsData.pagination.currentPage} of {bookingsData.pagination.totalPages}
                        </span>
                        <button
                            className="btn btn-secondary ms-2"
                            onClick={() => handlePageChange(bookingsData.pagination.currentPage + 1)}
                            disabled={bookingsData.pagination.currentPage === bookingsData.pagination.totalPages}
                        >
                            Next
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Dashboard;